.root {
  background-color: var(--palette-background-default);
  text-decoration: none;
  display: flex;
  flex-direction: column;
  > article {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    flex: 1;
  }
  img {
    border-top-left-radius: var(--dimensions-border-radius-card);
    border-top-right-radius: var(--dimensions-border-radius-card);
    overflow: hidden;
    width: 100%;
    height: 180;
    object-fit: cover;
  }
  span {
    color: var(--palette-text-hint);
    font-size: 0.8rem;
  }
  h3 {
    margin-bottom: 0.25rem;
    margin-top: 0;
  }
  p {
    color: var(--palette-text-secondary);
    margin-bottom: 0.5rem;
    margin-top: 1rem;
    flex: 1;
  }
  button {
    > svg {
      width: 0.8rem;
      height: 0.8rem;
      transform: rotate(180deg);
      margin-right: 1rem;
    }
    align-self: flex-start;
  }
}
