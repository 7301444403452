.root {
  width: 100%;
  & > form {
    width: 100%;
  }
}
.mailcheck {
  font-size: 0.8rem;
  padding: 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin: 0 var(--dimensions-border-radius-default) 2px var(--dimensions-border-radius-default);
}
.subscript {
  margin: 0.25rem 0;
  font-size: 0.8rem;
  color: var(--palette-text-secondary);
  button {
    color: var(--palette-text-primary);
    text-decoration: underline;
    padding: 0;
    margin: 0;
    font-weight: bold;
  }
  a {
    color: var(--palette-text-secondary);
    text-decoration: underline;
  }
}
.agreementButton {
  font-size: 0.8rem;
  color: var(--palette-text-default);
}
.overlay {
  background: var(--palette-background-default);
  max-width: 50rem;
  overflow: scroll;
}
.marketing {
  margin-top: 0rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  > p {
    flex: 1;
    margin-right: 1rem;
    margin: 0;
  }
}
.checkbox {
  cursor: pointer;
  margin: 0 12px 0 0;
}
.section {
  margin-bottom: 1.5rem;
  &:last-child {
    margin-bottom: 0rem;
  }
}
.signUpBtn {
  width: 100%;
  display: none;
}
