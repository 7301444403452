@import 'styles/mixins';

.background {
  background-color: var(--palette-background-secondary);
  height: 416px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
}
.header {
  @include londrina-title(small);
}
.show-more-button {
  align-self: center;
}
