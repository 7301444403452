.input {
  margin-bottom: 0.4rem;
}
.signUpFormSelector {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  h3 {
    margin: 10px 0px 15px 0px;
  }
  .active {
    opacity: 1;
  }
  .inactive {
    opacity: 0.5;
  }
}
.indicator {
  height: 3px;
  width: 0;
  background-color: var(--palette-accent-main);
  position: absolute;
  left: 0;
  transition: var(--animation-duration);
}
.signUpToggle {
  position: relative;
  margin-bottom: 20px;
  &:hover {
    & h3 {
      opacity: 1;
    }
    span {
      width: 20px;
    }
  }
}
.activeIndicator {
  width: 20px;
}
