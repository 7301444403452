@import 'styles/mixins';

.root {
  display: flex;
  flex-direction: column;
  @include md {
    flex-direction: row;
  }
}
.contentContainer {
  display: flex;
  flex-direction: column;
  @include md {
    flex: 1;
  }
}
.entryContainer {
  display: flex;
  padding: 1.5rem;
  width: 100%;
  margin: 2rem 0 0 0;
  background-color: vat(--palette-background-default);
  flex-direction: column;
  align-self: center;
  @include md {
    width: 320px;
    align-self: flex-start;
    display: flex;
    position: sticky;
    top: 3rem;
    flex-direction: column;
    margin: 0 0 0 3rem;
    background-color: var(--palette-background-default);
  }
}
