.root {
  border-radius: var(--dimensions-border-radius-card);
  &.interactive:hover {
    box-shadow: var(--shadow-hover);
  }
  &.interactive {
    box-shadow: var(--shadow-default);
  }
  &.outlined {
    border: 1px solid var(--palette-divider);
  }
  &.interactive:focus {
    box-shadow: var(--shadow-hover);
  }
}
