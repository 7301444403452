@import 'styles/mixins';

.root {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include sm {
    flex-direction: row;
    padding: 1rem;
    align-items: stretch;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  > li {
    margin: 0.25rem 1rem 0.25rem 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    @include sm {
      margin: 1rem;
      width: 22rem;
    }
  }
}
